import React from "react"
import { Link } from "gatsby"
import { PopupText } from "react-calendly"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Col, Row } from "react-bootstrap"
import styled from "styled-components"
import "../components/success.css"


const SecondPage = () => (
  <div>
  <div className = "outer">
  <div className = "inner">
    <h2>Thank You! Your Form Submission has been received.</h2>
    <PopupText
      pageSettings={{
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055'
      }}
      prefill={{
        customAnswers: {
          a1: 'a1',
          a10: 'a10',
          a2: 'a2',
          a3: 'a3',
          a4: 'a4',
          a5: 'a5',
          a6: 'a6',
          a7: 'a7',
          a8: 'a8',
          a9: 'a9'
        },
        email: 'test@test.com',
        firstName: 'Jon',
        lastName: 'Snow',
        name: 'Jon Snow'
      }}
      styles={{}}
      text="Want to immediately scheduele a meeting with Chrono? Click here!"
      url="https://calendly.com/chronogroupllc/60min"
      utm={{
        utmCampaign: 'Spring Sale 2019',
        utmContent: 'Shoe and Shirts',
        utmMedium: 'Ad',
        utmSource: 'Facebook',
        utmTerm: 'Spring'
      }}
    />
    <br></br>
    <br></br>
      <img src={require('../assets/illustrations/calendar.svg')} alt="Chrono Group" height ="400px"/>
      <div><Link to="/">Go back to our site</Link></div>
    </div>
    </div>

  </div>
)

export default SecondPage
